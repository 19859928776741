.icon {
  display: flex;
  border-radius: 50%;
  background-color: var(--bs-white);
}

.leaflet-marker-icon {
  span {
    font-family: "Oswald", sans-serif;
  }
}

.icon-flights {
  border: 2px solid var(--bs-white);
}

@keyframes border-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.icon-flights.animation {
  animation: border-pulse 1.5s infinite;
}

.icon-current {
  border: 2px solid var(--bs-white);
  animation: border-pulse 1.5s infinite;
}

.information-row {
  cursor: pointer;
  gap: 10px;
  padding: 10px;
  .icon {
    width: 35px;
    height: 35px;
    border: 1px solid var(--bs-gray-400);
    transition: border 0.5s ease-out;
  }

  &:hover {
    background-color: var(--bs-gray-100);
    .icon {
      box-shadow: inset 0px 0px 13px -5px rgb(139, 220, 255);
      border: 1px solid var(--bs-blue);
    }

    .information-row-name {
      color: var(--bs-gray-900);
    }
  }
}

.leaflet-marker-icon:not(.leaflet-control-locate-heading) {
  svg {
    width: 24px;
    height: 24px;
  }
}

.information-row-icon {
  svg {
    width: 24px;
    height: 24px;
  }
}

.icon-accessible-toilet > * {
  border: 1px solid var(--bs-white);
}

.leaflet-marker-icon:not(.leaflet-control-locate-location) {
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.icon-twitter {
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.leaflet-control-locate-heading {
  border: 0 !important;
  box-shadow: none !important;
}

.icon-usa svg,
.icon-eur svg {
  width: 30px !important;
  height: 30px !important;
}
